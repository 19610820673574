/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */


(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages
        var height = $(window).height();
        $('.bxslider .img-banner').height(height);

        $('.bxslider').bxSlider({
          mode: 'fade',
          auto: true,
          pager: false,
          pause: 10000
        });

        //$('.row').each(function (i, elem) {
        // $(elem)
        //    .find('.thumbnail')   // Only children of this row
        //    .matchHeight({byRow: false}); // Row detection gets confused so disable it
        //});
        $('.row').each(function (i, elem) {
          $(elem)
            .find('.thumbnails')   // Only children of this row
            .matchHeight({byRow: false}); // Row detection gets confused so disable it
        });

        $('.row').each(function (i, elem) {
          $(elem)
            .find('.content_block')   // Only children of this row
            .matchHeight({byRow: false}); // Row detection gets confused so disable it
        });

        $('.row').each(function (i, elem) {
          $(elem)
            .find('.content_footer')   // Only children of this row
            .matchHeight({byRow: false}); // Row detection gets confused so disable it
        });

        // $('.content_block').matchHeight({
        //   byRow: true,
        //   property: 'height'
        // });

        // $('.content_footer').matchHeight();


        $(window).load(function () {

          if ($(document).scrollTop() > 50) {
            console.log($(document).scrollTop());
            $('.navbar-fixed-top').addClass('shrink');
          } else if (!$('.navbar-fixed-top').hasClass("start")) {
            $('.navbar-fixed-top').removeClass('shrink');
          }
        });

        $(window).scroll(function (e) {
          if ($(document).scrollTop() > 50) {
            $('.navbar-fixed-top').addClass('shrink');
          } else if (!$('.navbar-fixed-top').hasClass("start")) {
            $('.navbar-fixed-top').removeClass('shrink');
          }
        });

        $(function () {

          $('.menu-item-has-children').hover(function () {
            console.log('tesint')
            $(this).addClass('open')
          }, function () {
            $(this).removeClass('open')
          });
        });


      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
        $('.scroll_arrow').click(function (e) {
          $('html, body').animate({
            scrollTop: $('.wrap').children('.container:first').offset().top - 20
          }, 'slow');
        });
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  $(document).ready(function () {
    $('body').append('<div class="popup-overlay">\n' +
      '  <div class="popup">\n' +
      '    <p>We use cookies on Everoze.com to help provide a better online experience. By using this website you agree to our use of  cookies. You can click "Close" to remove this message.   <a title="privacy policy" href="/app/themes/everoze/privacy-policy.pdf"\n' +
      '                 target="_blank">Website Privacy Policy</a></p>\n' +
      '    <a href="javascript:;" class="close popup-button">Close</a>\n' +
      '  </div>\n' +
      '</div>\n')

    function getCookie(cname) {
      var name = cname + "=";
      var ca = document.cookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }

    function setCookie(cname, cvalue, exdays) {
      var d = new Date();
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      var expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    //check to see if the submited cookie is set, if not check if the popup has been closed, if not then display the popup
    if (getCookie('popupCookie') != 'submited') {
      if (getCookie('popupCookie') != 'closed') {
        $('.popup-overlay').css("display", "flex").hide().fadeIn();
      }
    }

    $('a.close').click(function () {
      $('.popup-overlay').fadeOut();
      //sets the coookie to one minute if the popup is closed (whole numbers = days)
      setCookie('popupCookie', 'closed', 30);
    });


  });

  var formProcessing = false;

  $('.form').submit(function (event) {
    if(formProcessing) return;
    formProcessing = true;

    event.preventDefault();

    validateForm($(this));
  });


})(jQuery); // Fully reference jQuery after this point.


function validateForm(form) {
  var errors = 0;
  if (form.find("[name='name']").val().length === 0) {
    form.find("[name='name']").parent().addClass('error')
    errors++;
  } else {
    form.find("[name='name']").parent().removeClass('error')
  }
  if (form.find("[name='company']").val().length === 0) {
    form.find("[name='company']").parent().addClass('error')
    errors++;
  } else {
    form.find("[name='company']").parent().removeClass('error')
  }
  if (form.find("[name='email']").val().length === 0) {
    form.find("[name='email']").parent().addClass('error')
    errors++;
  } else {
    form.find("[name='email']").parent().removeClass('error')
  }

  if (form.find("[name='phone_number']").val().length === 0) {
    form.find("[name='phone_number']").parent().addClass('error')
    errors++;
  } else {
    form.find("[name='phone_number']").parent().removeClass('error')
  }
  if (form.find("[name='message']").val().length === 0) {
    form.find("[name='message']").parent().addClass('error')
    errors++;
  } else {
    form.find("[name='message']").parent().removeClass('error')
  }
  if (errors === 0) {
    grecaptcha.ready(function () {
      var site_key = jQuery('#site-key').data('sitekey');
      grecaptcha.execute(site_key, {action: 'contact_event'}).then(function (token) {
        form.prepend('<input type="hidden" name="token" value="' + token + '">');
        form.prepend('<input type="hidden" name="action" value="contact_event">');
        jQuery('body').prepend('<div id="spinner-wrapper" style="position: fixed;height:100vh;width:100vw;display: flex;justify-content: center;align-items: center;z-index: 9999;background:white;"><i class="fa fa-spinner fa-spin" style="font-size:46px"></i></div>');
        form.unbind('submit').submit();
      });
    });
  }
}


/** COOKIE**/
(function ($) {
  $.cookie = function (key, value, options) {
    if (arguments.length > 1 && (!/Object/.test(Object.prototype.toString.call(value)) || value === null || value === undefined)) {
      options = $.extend({}, options);

      if (value === null || value === undefined) {
        options.expires = -1;
      }

      if (typeof options.expires === 'number') {
        var days = options.expires, t = options.expires = new Date();
        t.setDate(t.getDate() + days);
      }

      value = String(value);

      return (document.cookie = [
        encodeURIComponent(key), '=', options.raw ? value : encodeURIComponent(value),
        options.expires ? '; expires=' + options.expires.toUTCString() : '', // max-age is not supported by IE
        options.path ? '; path=' + options.path : '',
        options.domain ? '; domain=' + options.domain : '',
        options.secure ? '; secure' : ''
      ].join(''));
    }
    options = value || {};
    var decode = options.raw ? function (s) { return s; } : decodeURIComponent;

    var pairs = document.cookie.split('; ');
    for (var i = 0, pair; pair = pairs[i] && pairs[i].split('='); i++) {
      // IE
      if (decode(pair[0]) === key) return decode(pair[1] || '');
    }
    return null;
  };

  $.fn.cookieBar = function (options) {
    var settings = $.extend({
      'closeButton': 'none',
      'hideOnClose': true,
      'secure': false,
      'path': '/',
      'domain': ''
    }, options);

    return this.each(function () {
      var cookiebar = $(this);

      // just in case they didnt hide it by default.
      cookiebar.hide();

      // if close button not defined. define it!
      if (settings.closeButton == 'none') {
        cookiebar.append('<a class="cookiebar-close">Dismiss</a>');
        $.extend(settings, { 'closeButton': '.cookiebar-close' });
      }

      if ($.cookie('cookiebar') != 'hide') {
        cookiebar.show();
      }

      cookiebar.find(settings.closeButton).click(function () {
        if (settings.hideOnClose) {
          cookiebar.hide();
        }
        $.cookie('cookiebar', 'hide', { path: settings.path, secure: settings.secure, domain: settings.domain, expires: 30 });
        cookiebar.trigger('cookieBar-close');
        return false;
      });
    });
  };

  // self injection init
  $.cookieBar = function (options) {
    $('body').prepend('<div class="ui-widget cookie-message" style="display: none"><div class=" ui-widget-header blue"><a href="/covid-19-policy">Please read our COVID-19 policy </a> and how this affects the way we work</div></div>');
    $('.cookie-message').cookieBar(options);
  };
})(jQuery);

(function ($) {
  $(document).ready(function () {
    $.cookieBar();
  });

})
(jQuery);
